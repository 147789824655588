<template>
    <div class="message">
        
        <div class="integralInfor">
            <div class="integralInforBtn flex alignCenter spacearound cursorP" :class="type == 1 ? 'integralCurr' :''" @click="changetype(1)">课程消息</div>
            <div class="integralInforBtn flex alignCenter spacearound cursorP" :class="type == 2 ? 'integralCurr' :''" @click="changetype(2)">系统消息</div>
            <div class="clear"></div>
        </div>
        
        <table style="width:850px;border:1px solid #e6e6e6;margin-top:15px;margin-left:25px;" cellspacing="0">
            <tr style="height:55px;background: #fafafa;">
                <th style="width:412px;">标题名称</th>
                <th style="width:211px;">时间</th>
                <th style="width:109px;">状态</th>
                <th style="width:110px;">操作</th>
            </tr>
            <tr style="height: 82px;" v-for="(im,ix) in tableData[page-1]" :key="ix">
                <td style="color:#333;" class="fontW">{{im.title}}</td>
                <td>{{im.ctime}}</td>
                <td>{{im.is_read == 0 ? '未查看' : '已查看'}}</td>
                <td>
                    <span class="cursorP" style="color:#2290FF;font-size:15px;" @click="lookDetail(im)">查看</span>
                </td>
            </tr>
        </table>
        <el-pagination
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="totalCount"
        :page-size="10"
        @current-change="currentChange">
        </el-pagination>
        <el-dialog
        :title="popupData.title"
        :visible.sync="dialogVisible"
        width="60%">
        <span>{{popupData.ctime}}</span>
        <div class="noscroll" style="height:400px;overflow:auto;margin-top:30px;">
            <div v-html="popupData.content"></div>
        </div>
        </el-dialog>
    </div>
</template>
<script>
import { information, informationRead } from '@/utils/Api/userList'
export default {
    name:'message',
    data(){
        return{
            tableData: [],
            page:1,
            type:1,
            totalCount: 0,
            dialogVisible: false,
            popupData:{}
        }
    },
    created(){
        this.tyechange()
    },
    methods:{
        async lookDetail(im){
            let {res} = await informationRead({info_id: im.id})
            if(!res){
                this.popupData = im
                im.is_read = 1
                this.dialogVisible = true
            }
        },
        changetype(ix){
            this.type = ix
            this.page = 1
            this.tableData = []
            this.tyechange()
        },
        tyechange(){
            let params = {
                type: this.type,
                page: this.page,
                limit: 10
            }
            this.initial(params)
        },
        async initial(params){
            let {res,count} = await information(params)
            this.totalCount = count
            if(res){
                this.tableData.push(res)
                return
            }
            if(this.type == 1){
                this.$message.error('课程消息获取失败')
                return
            }
            this.$message.error('系统消息获取失败')
        },
        currentChange(value){
            this.scrollTop()
            this.page = value
            console.log(this.tableData[value])
            console.log(this.tableData)
            if(this.tableData[value]){return}
            this.tyechange()
        }
    }
}
</script>
<style lang="scss" scoped>
.message{
    
}
table{
    td{
        border: 1px solid #e6e6e6;
    }
}
.integralInfor{
    margin-top: 10px;
    margin-left:25px;
    .integralInforBtn{
        width: 134px;
        height: 51px;
        opacity: 1;
        background: #FAFAFA;
        font-size: 16px;
        color: #333;
        float: left;
    }
    .integralCurr{
        color: #fff!important;
        background: #47d7e3!important;
    }
}
</style>